import React, { FC, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import { DangerouslySetInnerHtml, Layout } from 'layout';
import ProductTitle from 'components/ProductTitle';
import ProductFeatures from 'components/ProductFeatures';
import ProductDescription from 'components/ProductDescription';
import ProductAdvantages from 'components/ProductAdvantages';
import Button from 'common/Button';
import ProductCarousel from 'components/ProductCarousel';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Breadcrumbs from 'components/Breadcrumbs';
import ReviewStars from 'components/ReviewStars';
import ProductReview from 'components/ProductReview';
import RelatedProducts from 'components/RelatedProducts';
import { IProductPageProps } from './model';
import './ProductPageMain.scss';
import './ProductPageRTL.scss';

const ProductPage: FC<IProductPageProps> = ({ data: { umbracoProducts, relatedProducts } }) => {
  const {
    defaultCompositions,
    properties: {
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      title,
      amountCondoms,
      productAdvantages,
      productDescriptionText,
      productFeatures,
      buttons,
      image,
      sku,
      turnBackPDPText,
      buyNowDisclaimer,
    },
    url,
  } = umbracoProducts;
  const { isMobile } = useScreenRecognition();

  const urlToCategoryLink = useMemo(() => {
    const urlToCategory = url.split('/').filter((link) => link);
    urlToCategory.pop();

    return `/${urlToCategory.join('/')}`;
  }, [url]);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
      }}
    >
      {isMobile ? (
        <div className="product__breadcrumbs--mobile">
          <Link to={urlToCategoryLink}>
            <div className="icon-arrow-left" />
            <span>{turnBackPDPText}</span>
          </Link>
        </div>
      ) : (
        <Breadcrumbs url={url} className="product__breadcrumbs" />
      )}
      <div className="product-page">
        <div className="product-banner">
          {isMobile && (
            <ProductTitle title={title} amountCondoms={amountCondoms} classes="productTitleOrder" />
          )}
          <ProductCarousel
            isMobile={isMobile}
            images={image}
            isFirstViewport
            classes="product-carousel"
          />
          <div className="product-right-pack">
            {!isMobile && (
              <>
                <ProductTitle
                  title={title}
                  amountCondoms={amountCondoms}
                  classes="productTitleOrder"
                />
                <ReviewStars className="product-reviews" sku={sku} />
              </>
            )}
            <div className="product-buttons">
              {isMobile ? <ReviewStars sku={sku} className="product-reviews" /> : null}
              {buttons?.map((btn, index) => (
                <Button
                  key={`${btn.properties.buttonText}${index + 90}`}
                  ariaLabel={btn.properties.buttonText}
                  link={btn.properties.buttonLink[0].url}
                  btnColorVariant="dark"
                  className="product-buttons-styles"
                >
                  {btn.properties.buttonText}
                </Button>
              ))}
            </div>
            {buyNowDisclaimer ? (
              <DangerouslySetInnerHtml html={buyNowDisclaimer} className="product-disclaimer" />
            ) : null}
          </div>
        </div>

        {productFeatures && productFeatures.length > 0 ? (
          <ProductFeatures productFeatures={productFeatures} />
        ) : null}
        {productDescriptionText && productDescriptionText.length > 0 ? (
          <ProductDescription productDescriptionText={productDescriptionText} />
        ) : null}
      </div>
      {productAdvantages && productAdvantages.length > 0 ? (
        <ProductAdvantages productAdvantages={productAdvantages} />
      ) : null}
      <ProductReview sku={sku} />
      <div className="related-products__wrapper">
        <RelatedProducts
          relatedProducts={relatedProducts}
          relatedProductsCardCtaButton={umbracoProducts.properties.relatedProductsCardCtaButton}
          relatedProductsCtaButtonShow={umbracoProducts.properties.relatedProductsCtaButtonShow}
          relatedProductsTitle={umbracoProducts.properties.relatedProductsTitle}
          relatedProductsCardCTAButtonAriaLabel={
            umbracoProducts.properties.relatedProductsCardCTAButtonAriaLabel
          }
          relatedProductsCtaButton={umbracoProducts.properties.relatedProductsCtaButton}
        />
      </div>
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={sku}
        data-name={title}
        data-url={url}
      />
    </Layout>
  );
};
export const query = graphql`
  query ProductPage($url: String!, $links: [String]) {
    umbracoProducts(url: { eq: $url }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        image {
          properties {
            imageAltLabel
          }
          mobileImage: localImage {
            childImageSharp {
              fluid(maxWidth: 200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: localImage {
            childImageSharp {
              fluid(maxWidth: 420, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sku
        title
        amountCondoms
        turnBackPDPText
        productAdvantages {
          properties {
            productAdvantage
            productAdvantageComment
          }
        }
        buttons {
          properties {
            buttonText
            buttonLink {
              url
            }
          }
        }
        productDescriptionText {
          properties {
            descriptionText
            descriptionTitle
          }
        }
        productFeatures {
          properties {
            productFeaturesText {
              value
            }
            productTextTitle
          }
        }
        relatedProductsCardCtaButton
        relatedProductsCtaButtonShow
        relatedProductsTitle
        relatedProductsCardCTAButtonAriaLabel
        relatedProductsCtaButton {
          url
          name
        }
        buyNowDisclaimer
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default ProductPage;
