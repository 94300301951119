/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { FC, useMemo, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import GatsbyImage from 'common/GatsbyImage';
import LIMITS from 'gatsby-theme-husky/src/shared/static/limits';
import { ICarouselProps } from './model';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductCarousel.scss';
import './ProductCarouselRTL.scss';

const ProductCarousel: FC<ICarouselProps> = ({ isMobile, images, isFirstViewport, classes }) => {
  const [slideNumber, setSlideNumber] = useState(0);

  const NextArrow = ({ currentSlide, ...props }: { currentSlide?: any }) => {
    setSlideNumber(currentSlide);

    return <button type="button" {...props} />;
  };

  const PrevArrow = ({ ...props }) => <button type="button" {...props} />;

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const currentImage = useMemo(
    () => (num: number) => () => {
      setCurrentSlide(num);
    },
    []
  );

  return (
    <div
      className={classNames(`product-carousel`, {
        [`${classes}`]: classes,
      })}
    >
      {images ? (
        <GatsbyImage
          className="product-carousel__main-img"
          fluid={images[currentSlide!].desktopImage!.childImageSharp!.fluid}
          alt={images[currentSlide].properties.imageAltLabel}
          isLazyLoading={isFirstViewport}
        />
      ) : null}
      <Slider {...settings}>
        {images && images.length > 1
          ? images.map(({ mobileImage, desktopImage, properties: { imageAltLabel } }, index) => {
              const image = isMobile
                ? mobileImage?.childImageSharp.fluid
                : desktopImage?.childImageSharp.fluid;

              return image ? (
                <div
                  key={`${image.src}${imageAltLabel}`}
                  onClick={currentImage(index)}
                  className="slider-wrapper"
                >
                  <GatsbyImage
                    fluid={image}
                    alt={imageAltLabel}
                    isLazyLoading={isFirstViewport}
                    className="product-carousel__slide-img"
                  />
                </div>
              ) : null;
            })
          : null}
      </Slider>
      {images && images.length > LIMITS.IMAGES ? (
        <>
          {slideNumber !== 0 ? (
            <div className="product-arrow product-arrow--right icon-arrow-right" />
          ) : null}

          {slideNumber !== images.length - LIMITS.IMAGES ? (
            <div className="product-arrow product-arrow--left icon-arrow-right" />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default ProductCarousel;
